import React from "react";
import Modal from "react-bootstrap/Modal";

const PortfolioCardModal = ({ isOpen, hideModal, data }) => {
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        show={isOpen}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <button
            onClick={hideModal}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 29.699 29.699"
            >
              <g
                id="Group_17558"
                data-name="Group 17558"
                transform="translate(-1813.379 -47.379)"
              >
                <line
                  id="Line_246"
                  data-name="Line 246"
                  x2="36"
                  transform="translate(1840.956 49.5) rotate(135)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  id="Line_247"
                  data-name="Line 247"
                  x2="36"
                  transform="translate(1815.5 49.5) rotate(45)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </g>
            </svg>
          </button>
          <div className="new-modal-box">
            <figure>
              <img loading="lazy" src={data?.img} alt="news" />
            </figure>
            <h3>{data?.title}</h3>
            <p>{data?.subTitle}</p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PortfolioCardModal;
